//@import 'utils/mixins';

.main-img {
	min-height: 505px;
	background-size: cover!important;
	background-position: center;
	background-repeat: no-repeat!important;
	background: #000;
}

.main-container {
	color: #fff;
	text-align: center;
	padding: 100px 0 40px 0;
	&-title {
		font-family: $ff-Raleway;
		font-size: 38px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 7.6px;
		margin-bottom: 30px;
	}
	&-text {
		font-size: 17px;
		font-weight: 400;
		line-height: 28px;
		display: block;
		margin: 20px auto;
		max-width: 520px;
	}
}

.socials {
	display: flex;
	margin: 15px -10px;
	padding: 0;
	&_main {
		margin: 70px 0 15px 0;
		justify-content: center;
	}
	li {
		list-style: none;
		margin: 0 10px;
		display: flex;
		border-radius: 3px;
		overflow: hidden;
		span {
			color: #a3a3a3;
			font-size: 14px;
			font-weight: 700;
			@include flexCenter;
			background-color: #f8f8f8;
			border: 1px solid #cacaca;
			width: 40px;
			height: 30px;
		}
		a {
			@include flexCenter;
			width: 40px;
			height: 30px;
			i {
				color: #fff;
			}
			&.socials_facebook {
				background-color: #39579a;
			}
			&.socials_google {
				background-color: #f34430;
			}
			&.socials_pinterest {
				background-color: #39579a;
			}
			&.socials_message {
				background-color: #ababab;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.longrid {
	&-item {
		margin: auto;
		max-width: 830px;
		padding: 40px 15px;
	}
	&-full {
		position: relative;
		min-height: 250px;
		display: flex;
		align-items: center;
		margin: 10px 0;
		&-img {
			position: absolute;
			width: calc(50% - 10px);
			left: 0;
			height: 100%;
			background-size: cover!important;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	h2 {
		font-family: $ff-Raleway;
		font-size: 22px;
		font-weight: 700;
	}
	p {
		color: #4c4c4c;
		font-size: 14px;
		font-weight: 400;
		line-height: (21/14);
		margin: 20px 0;
		small {
			font-size: 12px;
		}
	}

}

.subscription {
	height: 77px;
	background-color: #e6543e;
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	&-info {
		color: #fff;
		position: relative;
		padding-left: 40px;
		&:before {
			position: absolute;
			content: "\f003";
			font-family: $ff-FontAwesome;
			display: block;
			color: #fff;
			font-size: 20px;
			left: 0;
			@include middleY;
		}
		&-title {
			font-family: $ff-Raleway;
			font-size: 21px;
			font-weight: 700;
			line-height: 21px;
		}
		&-text {
			font-size: 13px;
			font-weight: 400;
			line-height: 21px;
			display: block;
		}
	}
	&-wrap {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		input {
			height: 40px;
			padding: 6px 12px;
			display: block;
			border: 1px solid #f1f0f0;
			background-color: #fff;
			border-radius: 5px;
			&::placeholder {
				color: #959595;
				font-size: 16px;
				line-height: 24px;
			}
		}
		&-btn {
			margin-left: 10px;
			box-shadow: 0 0 20px rgba(53, 56, 56, 0.2);
			background-color: #ff624b;
			color: #fff;
			font-size: 14px!important;
			font-weight: 700!important;
			line-height: 24px!important;
			text-transform: uppercase;
			letter-spacing: 0.7px;
		}
	}
}

.last {
	padding: 40px 0;
	&-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&-title {
			color: #4c4c4c;
			font-family: $ff-Raleway;
			font-size: 17px;
			font-weight: 700;
			line-height: 21px;
			text-transform: uppercase;
			letter-spacing: 1.7px;
		}
		&-nav {
			padding: 0;
			display: flex;
			margin: 0;
			li {
				list-style: none;
				&:not(:last-child) {
					margin-right: 20px;
				}
				a {
					display: block;
					text-transform: uppercase;
					color: #d2d2d2;
					font-size: 14px;
					font-weight: 700;
					line-height: 24px;
					letter-spacing: 0.7px;
					@include transition3;
					&:hover {
						text-decoration: none;
						color: #2dd8fb;
					}
				}
				&.active {
					a {
						color: #2dd8fb;
					}
				}
			}
		}
	}
	&-body {
		margin: 10px 0;
		padding-bottom: 30px;
	}
}

.body-item {
	background-color: #fff;
	margin: 10px 0;
	display: block;
	&-img {
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
		}
	}
	&-title {
		color: #4c4c4c;
		font-size: 14px;
		font-weight: 700;
	}
	&-wrap {
		display: flex;
		align-items: center;
		text-align: center;
		padding: 20px 10px 20px 0;
		height: 100%;
	}
}

.full-slider {
	position: relative;
	.carousel-inner {
		.carousel-item {
			max-height: 865px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

#blogo-vidinis {
	footer {
		padding-top: 55px;
	}
}