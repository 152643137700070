//@import 'utils/mixins';

.sidebar {
	&-nav {
		display: flex;
		flex-direction: column;
		padding: 0;
		li {
			list-style: none;
			a {
				display: block;
				font-size: 12px;
				font-weight: 400;
				line-height: 24px;
				text-transform: uppercase;
				letter-spacing: 0.6px;
				color: #bfbfbf;
				@include transition3;
				&:hover {
					color: #000;
					text-decoration: none;
				}
			}
			&.active {
				a {
					color: #000;
				}
			}
		}
	}
}

.bar-wrap {
	padding-top: 40px;
}

#help-vidinis {
	.content {
		padding-left: 25px;
		padding-bottom: 50px;
		position: relative;
		&.border-left {
			padding-bottom: 0;
			&:before {
				content: '';
				position: absolute;
				width: 1px;
				height: 100%;
				background-color: $accent-color;
				left: 0;
			}
		}
		
		.article-title {
			color: #000000;
			font-family: $ff-Raleway;
			font-size: 22px;
			font-weight: 700;
			line-height: 21px;
			margin-bottom: 20px;
		}
		p {
			color: #4c4c4c;
			font-size: 14px;
			font-weight: 400;
		}
		.small-m {
			margin-bottom: 10px;
		}
		.form-container-line {
			margin: 35px 0 40px;
		}
		&-btn {
			box-shadow: 0 0 10px rgba(53, 56, 56, 0.2);
			font-weight: 700!important;
			text-transform: uppercase;
			margin-right: 10px;
			padding: 8px!important;
			letter-spacing: 0.7px;
			width: 83px;
		}
	}


	.content-description {
		padding-top: 10px;
	}
}