//@import 'utils/mixins';

/*
	Nauda Page
*/

@mixin article-title($bg) {
	position: relative;
	font-family: $ff-Raleway;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 1.16;
	&:after {
		content: '';
		position: absolute;
		top: -18px;
		height: 2px;
		width: 28px;
		left: 0;
		background-color: $bg;
	}
}

@mixin article-content {
	font-size: 13px;
	font-weight: 400;
	padding-top: 25px;
}

@mixin article-block($bg) {
	&.pl-150 {
		padding-left: 154px;
	}
	&.pl-198 {
		padding-left: 204px;
		background-image: url('#{$img-path}/shape-9.png');
		background-repeat: no-repeat;
		background-size: 110px 110px;
		background-position: 78px 40px;
	}
	padding-left: 58px;		
	padding-top: 80px;
	padding-right: 60px;
	padding-bottom: 50px;
	&-title {
		@include article-title($bg);
	}
	&-content {
		font-size: 13px;
		font-weight: 400;
		padding-top: 25px;
	}
	&-footer {
		.nav {
			padding-top: 30px;
			justify-content: space-between;
		}
		.nav-item {
			display: flex;
			align-items: center;
			a {
				padding: 0;
				font-size: 10px;
				color: #c6c6c6;
			}
			// &:first-child a {
			// 	padding-left: 0;
			// }
		}
	}
	&.bg-white {
		@include app_box-shadow;
	}
}

@mixin logo-icon {
	position: absolute;
	top: -134px;
	left: -405px;
}

.layer-img-1 {
	background-image: url('#{$img-path}/layer-235.png');
}
.layer-img-2 {
	background-image: url('#{$img-path}/layer-236.png');
}
.layer-img-3 {
	background-image: url('#{$img-path}/layer-231.png');
}

#nauda {
	[class^="col-"] {
		padding-left: 9px;
		padding-right: 9px;
	}
	.banner-img-bg {
		min-height: 618px;
		background-color: black;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: white;
		&-content-wrapper {
			//$p: 15px;
			// margin: 0 auto;
			// padding-left: $p;
			// padding-right: $p;
			//max-width: 692px + $p*2;
			padding-top: 55px;
		}
		&-title {
			display: block;
			text-align: center;
			&_small {
				font-size: 10px;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 2px;
				padding-bottom: 28px;
			}
			&_default {
				font-size: 28px;
				font-weight: 300;
				line-height: 1.6;
			}
			&_medium {
				font-size: 15px;
				font-weight: 400;
				padding-top: 47px;
			}		
		}
		.button-wrapper {
			text-align: center;
			padding-top: 30px;
		}
		.btn-link {
			color: inherit;
			font-size: 24px;
			&:hover,
			&:focus {
				color: white;
				text-decoration: none;
			}
		}
		&.layer-img-3 {
			.banner-img-bg {
				&-title {
					&_default {
						font-family: $ff-Raleway;
						text-transform: uppercase;
						font-size: 30px;
						font-weight: 700;
						letter-spacing: 6px;
					}					
					text-align: left;					
				}
			}
			.button-wrapper {
				text-align: left;
				padding-top: 60px;
				padding-bottom: 60px;
				.btn {
					font-size: 18px;
				    text-transform: uppercase;
				    padding-left: 40px;
				    padding-right: 40px;
				    padding-top: 15px;
				    padding-bottom: 14px;
				    border-radius: 32px;
					font-weight: 600;
					letter-spacing: 0.7px;
				}
			}
		}
	}
	.banner-2 {
		position: relative;
		overflow-x: hidden;
		&:before,
		&:after {
			position: absolute;
			content: '';
			background-repeat: no-repeat;
			z-index: 99;
		}
		.mt-280 {
			margin-top: 283px;
		}
		.mt-60 {
			margin-top: 60px;
		}
		margin-top: -198px;
		padding-top: 134px;
		color: #4c4c4c;
		&-article-block {
			position: relative;
			@include article-block($bg-yellow);
			.bg-icon {
				@include logo-icon();
			}
			.bg-icon-left {
				position: absolute;
				top: 90px;
				left: 0;
			}
			.bg-icon-rigth {
				position: absolute;
				top: -91px;
				right: -44px;
			}
		}
	}
	.banner-3 {
		&-article-block {
			padding-top: 55px;
			padding-left: 15px;
			&-title-lg {
				font-size: 72px;
				color: #e2e2e2;
				font-weight: 300;
				padding-bottom: 46px;
			}
			&-title {
				@include article-title($bg-yellow);
			}
			&-content {
				@include article-content;
				max-width: 510px;
				padding-bottom: 134px;
			}
		}
	}
	.banner-5 {
		overflow-x: hidden;
		position: relative;
		&:before,
		&:after {
			position: absolute;
			content: '';
			background-repeat: no-repeat;
			z-index: 99;
		}
		&-title {
			font-size: 28px;
			color: #424242;
			text-align: center;
			font-weight: 300;
			padding-top: 80px;
			padding-bottom: 80px;
		}
		&-article-block {
			@include article-block($bg-blue);
			.bg-icon {
				@include logo-icon();
			}
			.bg-icon-rigth {
				position: absolute;
				top: 49px;
				right: -62px;
			}
			&.pr-125 {
				padding-right: 125px;
			}
		}

	}
	.banner-6 {
		position: relative;
		&:before,
		&:after {
			position: absolute;
			content: '';
			background-repeat: no-repeat;
			z-index: 99;
		}
		&-title {
			font-size: 48px;
			font-weight: 300;
			color: #979797;
			padding-top: 70px;
			padding-bottom: 63px;
		}
		&-article-block {
			@include article-block($bg-yellow);
			padding-bottom: 25px;
			.bg-icon {
				@include logo-icon();
			}
			&-footer {
				padding-bottom: 80px;
				.nav {
					max-width: 500px;
				}
			}

		}
	}
}

.chart-container {
	height: 110%;
	margin-top: -10%;
	display: flex;
	align-items: flex-end;
	&-col {
		display: flex;
		flex-direction: column;
		flex: 0 0 72px;
		max-width: 72px;
		padding-bottom: 15px;
		&:not(:last-child) {
			margin-right: 36px;
		}
		.chart-col {
			&-header {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: $bg-yellow;
				color: white;
				height: 68px;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				margin-bottom: 5px;
				span {
					display: block;
					font-family: $ff-Raleway;
					font-weight: 700;
					&:first-child {
						font-size: 28px;
						line-height: 1;
						&:before {
							content: '+';
							font-size: 22px;
							padding-right: 2px;
						}
					}
					&:last-child {
						font-size: 16px;
					}
				}
				
			}
			&-body {
				background-color: #dadada;
				flex: 1;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}
			&-footer {
				font-size: 15px;
				color: #4c4c4c;
				font-weight: 700;
				text-align: center;
				line-height: 1.1;
				padding-top: 15px;
				padding-bottom: 45px;
			}
		}
	}
}