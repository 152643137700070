//@import 'utils/mixins';

.team-head {
	min-height: 615px;
	text-align: center;
	//background: url("#{$img-path}/team.png") no-repeat center;
	background-position-y: bottom;
	background-color: #fff;
	& + .container {
		position: relative;
		z-index: 2;
	}
}

.team-headline {
	&-title {
		font-size: 28px;
		line-height: 28px;
		color: #4c4c4c;
		margin: 15px 0;
	}
	&-text {
		margin: 15px 0;
		display: block;
		font-size: 13px;
		line-height: 21px;
		font-weight: 400;
		&_partnerial {
			font-size: 17px;
			line-height: 28px;
			max-width: 565px;
			margin: 15px auto;
			text-align: center;
			color: #4c4c4c;
		}
	}
}

.team-step {
	padding: 35px 35px 20px 30px;
	position: relative;
	background-color: #fff;
	margin-bottom: 20px;
	&:before {
		content: '';
		display: block;
		width: 33px;
		height: 33px;
		border-radius: 50%;
		border: 5px solid #7dd1e2;
		position: absolute;
		left: -17px;
		top: 30px;
	}
	&-title {
		font-size: 22px;
		line-height: 21px;
		font-weight: 700;
		font-family: $ff-Raleway;
		color: #000;
	}
	&-text {
		font-size: 13px;
		line-height: 21px;
		display: block;
		font-weight: 400;
		margin: 10px 0;
	}
}

.team-steps-wrap .col-md-6:first-child .team-step:first-child {
	margin-top: -20px;
}

.team-steps-wrap .col-md-6:last-child .team-step:first-child {
	margin-top: -40px;
}

.team-info-wrap {
	padding: 40px 0;
	&_partnerial {
		padding: 70px 0 30px 0;
	}
}

.team-info {
	&_indent {
		margin: 50px 0;
	}
	&-text {
		font-size: 14px;
		line-height: 21px;
		font-weight: 400;
		color: #4c4c4c;
		display: block;
		margin: 10px 0;
		&_bold {
			font-weight: 700;
		}
	}
	&-title {
		font-size: 22px;
		font-weight: 700;
		font-family: $ff-Raleway;
	}
}

.team-tabs-nav {
	//background-color: white;
	//height: 400px;
	padding-top: 30px;
	position: relative;
	z-index: 1;
	ul {
		position: relative;
		display: flex;
		list-style: none;
		padding-left: 0;
		margin: 0 auto;
		max-width: 850px;
		height: 293px;
		overflow: hidden;
		//background: #000;
		//opacity: 0.2;
		z-index: 10;
		li {
			//height: 100%;
			position: absolute;
			bottom: 0;
			top: 0;
			&.team-tabs-nav-tab-1 {
				left: 0;
				max-width: 19%;
				top: -2%;
				z-index: 8;
			}
			&.team-tabs-nav-tab-2 {
				top: -4%;
				max-width: 21%;
				left: 11%;
			}
			&.team-tabs-nav-tab-3 {
				top: 6%;
				left: 24%;
				max-width: 20%;
				z-index: 8;
			}
			&.team-tabs-nav-tab-4 {
				top: -9%;
				left: 38.5%;
				z-index: 9;
				max-width: 24%;
				//transform: rotate(6deg);
			}
			&.team-tabs-nav-tab-5 {
				left: 55%;
				max-width: 19%;
				top: 8%;
				z-index: 8;
			}
			&.team-tabs-nav-tab-6 {
				left: 61%;
				max-width: 19%;
				top: 5%;
				z-index: 7;
			}
			&.team-tabs-nav-tab-7 {
				left: 72%;
				max-width: 18%;
				top: 10%;
			}
			&.team-tabs-nav-tab-8 {
				right: -1%;
    			max-width: 18%;
    			top: 7%;
			}
			a {			
				color: transparent;
				display: block;
				img {
					max-width: 100%;
					height: auto;
					filter: blur(3px) grayscale(100%);
					transition: filter 0.3s ease-in-out;
				}
				&:hover {
					img {
						filter: blur(0px) grayscale(100%);
					}
				}
				&.active {
					img {
						filter: blur(0px) grayscale(100%);
					}
				}
				&.multicolor {
					img {
						filter: blur(0px) grayscale(0%);
					}
				}
			}
		}
		
	}
}