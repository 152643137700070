@mixin transition3 {
	transition: all 0.3s ease;
}

@mixin middleX {
	left: 50%;
	transform: translateX(-50%);
}

@mixin middleY {
	top: 50%;
	transform: translateY(-50%);
}

@mixin flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin responImg {
	max-width: 100%;
	height: auto;
	display: block;
}


@mixin list-no {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
}


@mixin ff-FontAwesome {
	font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
}

@mixin app_box-shadow {
	box-shadow: 0 0 20px rgba(53, 56, 56, 0.2);
}

.container {
	padding-left: 10px;
	padding-right: 10px;
}

[class^="col"] {
	padding-left: 10px;
	padding-right: 10px;
}
.row {
	margin-left: -10px;
	margin-right: -10px;
}

.text-blue {
	color: $text-blue;
}

.iframe-responsive {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	iframe, object, embed, video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.text-red {
	color: $text-red;
}



	

// =============================================================================
// String Replace
// =============================================================================

// @function str-replace($string, $search, $replace: "") {
// 	$index: str-index($string, $search);

// 	@if $index {
// 		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
// 	}

// 	@return $string;
// }

// =============================================================================
// Font Face
// =============================================================================

// @mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
// 	$src: null;

// 	$extmods: (
// 		eot: "?",
// 		svg: "#" + str-replace($name, " ", "_")
// 	);

// 	$formats: (
// 		otf: "opentype",
// 		ttf: "truetype"
// 	);

// 	@each $ext in $exts {
// 		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
// 		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
// 		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
// 	}

// 	@font-face {
// 		font-family: quote($name);
// 		font-style: $style;
// 		font-weight: $weight;
// 		src: $src;
// 	}
// }