//@import 'node_modules/bootstrap/scss/bootstrap';
//@import 'mixins';
//@import 'header';
//@import 'footer';

//@import 'uzsklanda-page';
//@import 'nauda-page';
//@import 'banners';
//@import 'typography';
//@import 'elements';



// ---------------------------------------------------------
a {
	&:hover,
	&:focus {
		color: inherit;
	}
}
h1, h2, h3, h4 {
	margin-bottom: 0;
}

html, body {
	//height: 100%;
	margin: 0;
	padding: 0;
}

html {
	font-size: 16px;
}

body {
	font-family: $ff-Open-Sans;
	//font-family: $ff-PT-Sans;
  background-color: $bg-body;
  color: $text-default-color;
  display: flex;
	flex-flow: column;
	min-height: 100vh;
}

main,
.main {
	flex: 1;
	position: relative;
}
.card-columns {
	column-count: 2;
}

.main-box {
	&_white {
		background-color: #fff;
	}
}

.list-circle {
	@include list-no;
	padding-left: 11px;
	li {
		position: relative;
		padding-left: 25px;
		padding-bottom: 10px;
		padding-right: 10px;
		&:before {
			content: '';
			position: absolute;
			width: 15px;
			height: 15px;
			border: 2px solid #7dd0e2;
			border-radius: 50%;
			top: 2.5px;
			left: 0;
		}
	}
}

.header-background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 529px;
	background-repeat: no-repeat;
	background-size: cover;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $bg-blue;
		opacity: 0.65;
	}
}

.container {
	@media (min-width: 1200px) {
		max-width: 1200px;
	}
}

/*
	Content
*/
#help-page {
	.content-description {
		padding-bottom: 45px;
	}
}
.content-box {
	.content-header {
		padding-top: 67px;
	}
	.content-title,
	.content-subtitle,
	.content-subtitle-medium {
		text-align: center;
	}
	.content-subtitle {
		font-size: 15px;
		display: block;
		padding-top: 10px;
		color: #9d9d9d;
	}
	.content-subtitle-medium {
		display: block;
		font-size: 20px;
		color: #939393;
		font-weight: 400;
	}
	.content-title {
		font-size: $content-title;
		color: $text-color-dark;
		font-family: $ff-Raleway;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 7.6px;
		&_line {
			&:after {
				content: '';
				display: block;
				width: 33px;
				height: 3px;
				background-color: #000;
				margin: 40px auto 30px;
			}
		}
		&_partnerial {
			margin-bottom: 40px;
		}
	}
	@include media-breakpoint-down(lg) {
		.content-title {
			font-size: 28px;
		}
	}
	@include media-breakpoint-down(sm) {
		.content-title {
			font-size: 24px;
		}
	}
	.search-box {
		padding-top: 74px;
		&_vidinis {
			padding-top: 30px;
		}
		.input-group-text {
			background-color: white;
			color: $text-blue;
		}
		.input-group {
			position: relative;
			&:before {
				font-family: $ff-FontAwesome;
				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
				content: "\f002";
				font-size: 20px;
				color: $text-blue;
				z-index: 99;;
			}
		}
		input {
			padding-left: 50px;
			font-size: 21px;
			padding-top: 10px;
    		padding-bottom: 10px;
			background-color: white;
			&:focus {
				border: 1px solid #c9c9c9;
				box-shadow: 0 0 0 5px rgba(209, 209, 209, 0.2);
			}
			
		}
		
	}
	.list-box {
		@include list-no;
		padding-top: 105px;
		.list-box-item {
			a {
				&:hover,
				&:focus {
					color: inherit;
					text-decoration: none;
				}
				text-transform: uppercase;
			}
		}
		.card {
			display: flex;
			color: $text-default-color;
			text-align: center;
			margin-bottom: 24px;
			height: 179px;
			border: 1px solid $border-color;
			border-radius: 5px;
			@include app_box-shadow;
			img {
				display: block;
				margin: auto;
			}
			&:hover,
			&:focus {
				color: inherit;
				text-decoration: none;
			}
			.card-body {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: 40px;
				padding: 20px;
			}
			.card-title {
				margin-top: auto;
    		margin-bottom: 0;
    		text-transform: uppercase;
    		font-size: 13px;
    		font-weight: 700;
    		color: #969696;
    		letter-spacing: 1px;
			}
		}
	}
}

.content-description {
	padding-top: 30px;
	.card {
		&:not(:last-child) {
			margin-bottom: 18px;
		}		
		//border-radius: 0;
		border-color: transparent;
		&_transparent {
			background-color: transparent;
		}
		.card-title {
			font-family: $ff-Raleway;
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 0;
		}
		.card-body {
			padding-left: 22px;
			padding-right: 49px;
    	padding-top: 22px;
		}
	}
	ul {
		@include list-no;
		padding-top: 25px;
		//padding-bottom: 20px;
		margin-bottom: 0;
		li {
			position: relative;
			padding-left: 28px;
			line-height: 1.28;
    	padding-bottom: 16px;
    	font-size: 14px;
    	font-family: $ff-Open-Sans;
			&:before {
				position: absolute;
				content: "\f016";
				font-family: $ff-FontAwesome;
				color: $text-blue;
				top: 0;
				left: 0;
			}
		}
	}
	.icon-file-alt {
		color: $text-blue;
	}
}

#vidinis,
#integracijos {
	font-size: 14px;
	.card-columns {
		padding-bottom: 73px;
		padding-top: 58px;
		@include media-breakpoint-down(md) {
			column-count: 1;
			padding-bottom: (73/2)*1px;
			padding-top: (58/2)*1px;
		}
	}
	.boxiti-bullets {
		display: flex;
		align-items: flex-start;
		&-content {
			padding-top: 38px;
			padding-bottom: 80px;
			//flex: 0 0 72.2%;
		}
	}
	.block-border-blue {
		margin-top: 17px;
		border: 4px solid $bg-blue;
		padding: 30px 20px;
		font-size: 20px;
		font-family: $ff-Raleway;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 1.2;
		color: #7c7c7c;
		max-width: 220px;
		margin-left: auto;
		margin-right: 0;
	}
	@include media-breakpoint-down(sm) {
		.boxiti-bullets {
			display: block;
			padding-bottom: 30px;
			&-content {
				padding-bottom: 0;
			}
		}
		.block-border-blue {
			max-width: 100%;
		}
	}
}

.table-section {
	padding-top: 53px;
	padding-bottom: 67px;
	.h5-title {
		padding-bottom: 20px;
	}
}

.table-container {
	&-header {
		display: flex;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 11px;
		position: relative;
		padding-top: 13px;
		padding-bottom: 13px;
		&:before {
			content: '';
			position: absolute;
			height: 1px;
			bottom: 0;
			left: -10px;
			right: -10px;
			background-color: #4c4c4c;
		}
	}
	&-body {
		padding-bottom: 9px;
		padding-top: 9px;
	}
	.table-row {
		display: flex;
		padding-top: 9px;
		padding-bottom: 9px;
		&:nth-of-type(2n) {
			background-color: #fafafa;
		}
		.table-column-5 {
			color: #d3cccc;
			letter-spacing: 0.14px;
		}
	}
	.table-column {
		&-1 {
			flex: 0 0 8%;
		}
		&-2 {
			flex: 0 0 19%;
		}
		&-3 {
			flex: 0 0 47%;
		}
		&-4 {
			flex: 0 0 15%;
			font-weight: 700;
		}
		&-5 {
			flex: 0 0 11%;
			text-transform: uppercase;
			font-weight: 700;
			&.text-blue {
				color: #2dd8fb;
			}
		}
		&-1,
		&-2,
		&-3,
		&-4 {
			padding-right: 10px;
		}
	}
}

@include media-breakpoint-down(md) {
	.table-section {
		padding-top: 40px;
    	padding-bottom: 40px;
		.container {
			overflow-x: scroll;
		}
	}
	.table-container {
		min-width: 700px;
	}
}

/*

 	Registration Page

*/

#registracija {
	.content-box {
		.content-header {
			padding-bottom: 24px;
			.content-title,
			.content-subtitle {
				color: white;
			}			
		}
	}
}

#home-2,
#homepagev2 {
	.between-style {
		min-height: 147px;
		&.back-img.bg-red {
			background: $text-red;
			.btn.text-red  {
				&:hover,
				&:focus,
				&.active {
					color: $text-red;
				}
			}
		}
		.between-style-info-title {
			padding-top: 9px;
		}
		.between-style-info-text {
			padding-top: 5px;
		}
		&-wrap {
			padding-right: 25px;
			.btn {
				font-size: 13px;
				padding: 9.5px 20px;
				height: auto;
				box-shadow: none;
			}
		}
	}
	.frame {
		background-image: url('#{$img-path}/bd-video-section.png');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center 120px;
		background-color: #f7f7f7;
		padding-bottom: 240px;
		padding-top: 140px;
		&-title {			
			color: #616161;
			max-width: none;
		}
		&-subtitle {
			color: #616161;
			font-size: 16px;
			padding-top: 15px;
		}
	}
	footer {
		padding-top: 55px;
	}
}

#isbandyti {
	.form-wrapper {
		margin-bottom: 120px;
		@include media-breakpoint-up(lg) {
			margin-bottom: 60px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}
	}
	.content-box {		
		.content-header {
			padding-bottom: 70px;
			padding-top: 180px;
			max-width: 618px;
			margin: 0 auto;
			@include media-breakpoint-up(lg) {
				padding-top: 150px;
			}
		}
		.content-subtitle {
			padding-top: 20px;
			color: #3c3c3c;
			font-size: 15px;
		}
	}
	@include media-breakpoint-down(md) {
		.content-box {
				.content-header {
				padding-top: 100px;
			}
		}
	}
	@media (max-width: 365px) {
		.content-box {
				.content-header {
				padding-top: 60px;
			}
		}
	}
}