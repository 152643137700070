//@import 'vendors/bootstrap';
//@import 'utils/mixins';

/*
	Elements 
*/

// Carousels

.carousel {
	padding-bottom: 80px;
}
.carousel-indicators {
	bottom: 50px;
	li {
		border: 3px solid #4c4c4c;
		border-radius: 50%;
		height: 15px;
		width: 15px;
		margin-right: 7px;
	    margin-left: 7px;
		&.active {
			background-color: #4c4c4c;
		}
	}
}

// Forms
.form-wrapper {
	box-shadow: 0 0 20px rgba(53, 56, 56, 0.2);
	margin-left: 10px;
	margin-right: 10px;
	overflow: hidden;
	&_margin {
		margin-top: -125px;
	}
	@media (max-width: 420px) {
		margin-left: 0;
		margin-right: 0;
	}
}
.form-container {
	&-adresses {
		span {
			display: block;
			color: #4c4c4c;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}
	}
	&-line {
		width: 100%;
		height: 1px;
		background-color: #d5d5d5;
		margin: 30px 0;
	}
	&-subtitle {
		color: #4c4c4c;
		font-family: $ff-Raleway;
		font-size: 17px;
		font-weight: 700;
		line-height: 21px;
		margin: 30px 0;
	}
	hr {
		margin-bottom: 35px;
		margin-top: 18px;
		background-color: $border-color;
	}
	& + & {
		box-shadow: inset 0 20px 20px -21px rgba(53, 56, 56, 0.2);
	}
	&.no-shadow {
		box-shadow: none;
	}
	$pl: 50px;
	$pr: 50px;
	//padding: 45px $pr 40px $pl;
	padding-left: $pl;
	padding-right: $pr;
	
	.bg-white {
		background-color: white;
	}
	&-header {
		padding-top: 40px;
		&.pt-90 {
			padding-top: 112px;
		}
		&.mb-20 {
			margin-bottom: 20px;
		}
		.form-title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 6px;
			&-title {
				color: #4c4c4c;
				font-family: $ff-Raleway;
				font-weight: 700;
				padding-right: 25px;
				font-size: 22px;
				&_contakt {
					color: #000;
					margin-bottom: 30px;
				}
			}
			&.pb-50 {
				padding-bottom: 55px;
			}
		}
		.pagination-nav {
			margin-top: 0;
			margin-bottom: 0;
		}
		.pagination-nav li {
			&:last-child {
				margin-right: 0;
			}
		}
		.button-wrapper {
			display: flex;
			justify-content: space-between;
			padding-top: 50px;
			padding-bottom: 25px;

			.btn {
				&:not(:last-child),
				&:first-child {
					margin-right: 28px;
					max-width: 320px;
				}
				padding-top: 10.5px;
				padding-bottom: 10.5px;
				flex: 1;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 700;
				max-width: 50%;
				i {
					font-size: 16px;
					margin-right: 5px;
				}

			}
		}
	}
	@include media-breakpoint-down(lg) {
		padding-left: 35px;
		padding-right: 35px;
		&-header {
			.form-title {
				&-title {
					font-size: 18px;
				}
			}
			&.pt-90 {
				padding-top: 90px;
			}
		}
		.button-wrapper {
			.btn {
				font-size: 13px;
				&:not(:last-child),
				&:first-child {
					margin-right: 28px;
					max-width: 254px;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding-left: 25px;
		padding-right: 25px;
		&-header {
			padding-top: 30px;
			&.pt-90 {
				padding-top: 50px;
			}
			.form-title {
				&-title {
					font-size: 16px;
				}
			}
		}
		.button-wrapper {
			padding-top: 20px;
			.btn {
				font-size: 12px;
				&:not(:last-child),
				&:first-child {
					max-width: 219px;
					margin-right: 14px;
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		a + .button-wrapper {
			padding-top: 0;
		}
		.button-wrapper {
			width: 100%;
			.btn {
				&:not(:last-child),
				&:first-child {
					max-width: 100%;
					margin-right: 0;
				}	
			}
		}
	}
	@media (max-width: 420px) {
		padding-left: 12px;
		padding-right: 12px;
		.button-wrapper {
			flex-wrap: wrap;
			.btn {
				white-space: pre-wrap;
				&:not(:first-child) {
					margin-top: 5px;
				}
				max-width: 100%;
			}
		}
	}
	@media (max-width: 365px) {
		&-header {
			.form-title {
				&-title {
					font-size: 14px;
					padding-right: 5px;
				}				
			}
		}
	}
	&-body {
		//padding-top: 30px;
		padding-bottom: 20px;
		&-title {
			margin-bottom: 22px;
			text-align: center;
			position: relative;
			&:before {
				position: absolute;
				content: '';
				left: 0;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 1px;
				background-color: $border-color;
				//z-index: -1;
			}
			.bg-white {
				position: relative;
				padding-left: 13px;
				padding-right: 13px;
				background-color: white;
				color: #b5b5b5;
				text-transform: uppercase;
				text-align: center;
				letter-spacing: 2.8px;
				font-weight: 700;
				z-index: 2;
			}
		}
		.form-group {
			margin-bottom: 0;
			padding-bottom: 18px;
			&.input-pseudoselect {
				position: relative;
				.form-control {
					padding-right: 45px;
				}				
				&:before {
					top: 43%;
					right: 23px;
					position: absolute;
					content: '';
					width: 0;
					height: 0;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-top: 12px solid #7b7b7b;
					transform: translateY(-50%);
				}
			}
		}
		.form-row {
			.col:not(:last-child) {
				padding-right: 18px;
			}
		}
		.form-control {
			color: black;
			font-size: 18px;
			padding-top: 9.5px;
    		padding-bottom: 9.5px;
			padding-left: 20px;
			padding-right: 20px;
    		&:focus {
    			box-shadow: none;
    			border: 1px solid #2dd8fb;
    		}
    		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		    color: #b5b5b5;
		    opacity: 1; /* Firefox */
			}

			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			    color: #b5b5b5;
			}

			&::-ms-input-placeholder { /* Microsoft Edge */
			    color: #b5b5b5;
			}
		}
		textarea.form-control {
			resize: none;
			min-height: 140px;
		}
		.form-group-title {
			padding-bottom: 13px;
			color: #4c4c4c;
			font-family: $ff-Raleway;
			font-weight: 700;
			font-size: 12px;
			text-transform: uppercase;
		}
		.col-6 {
			flex: 0 0 42%;
		}
	}
	@include media-breakpoint-down(lg) {
		&-body {
			&-title {
				font-size: 14px;
			}
			.form-control {
				font-size: 16px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		&-body {
			padding-bottom: 10px;
			&.mb-5 {
				margin-bottom: 0 !important;
			}
			.form-group {
				&.input-pseudoselect {
					&:before {
						top: 40%;
						right: 15px;
					}
				}
			}
			.form-control {
				font-size: 14px;
				padding-left: 10px;
			}
			.form-row {
				.col:not(:last-child) {
					padding-right: 7px;
				}
			}
		}
	}
	@media (max-width: 420px) {
		&-body {
			.form-group {
				&.input-pseudoselect {
					&:before {
						top: 38%;
						border-left: 6px solid transparent;
    					border-right: 6px solid transparent;
    					border-top: 10px solid #7b7b7b;
					}
				}
			}
		}
	}
	&-footer {
		position: relative;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		color: #c7c7c7;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;
		padding-top: 30px;
		padding-bottom: 40px;
		//padding-bottom: 39px;
		&:before {
			position: absolute;
			content: '';
			background-color: $border-color;
			top: 0;
			left: -$pl;
			right: -$pr;
			height: 1px;
		}
		.w100 {
			width: 100%;
			text-align: right;
		}
		.form-check.form-check-inline {
			color: #687280;
			font-size: 14px;
			margin-right: 0;
			text-transform: none;
			font-weight: 400;
			padding-bottom: 13px;
			a {
				text-decoration: underline;
				color: inherit;
				padding-right: 0;
			}
		}
		.btn {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 14px;
			min-width: 180px;
			padding-top: 9.5px;
			padding-bottom: 9.5px;
			letter-spacing: 0.7px;
			&.btn-link {
				color: inherit;
				padding-left: 0;
				padding-right: 9px;
				min-width: auto;
				letter-spacing: 0.14px;
				&:hover,
				&:focus {
					color: darken(#c7c7c7, 10%);
				}
			}
		}			
		.form-check {
			flex: 0 0 100%;
			text-align: right;
		}
		&_flex {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.form-check {
				flex: none;
			}
		}
		@include media-breakpoint-down(lg) {
			.btn {
				font-size: 13px;
				min-width: 130px;
			}
		}
		@include media-breakpoint-down(sm) {
			padding-top: 20px;
			padding-bottom: 20px;
			.btn {
				font-size: 12px;
				&.btn-link {
					align-self: flex-end;
				}
			}
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			justify-content: center;
			.btn {
				width: 100%;
				margin: 0;
			}
		}
	}
	&-btn {
		text-transform: uppercase;
		display: block;
		width: 100%;
		font-weight: 700;
		letter-spacing: 0.7px;
		font-size: 14px;
		padding: 12.5px 0;
		margin-bottom: 25px;
		box-shadow: 0 0 20px rgba(53, 56, 56, 0.2);
	}
	&-link {
		font-size: 14px;
		font-weight: 700;
		line-height: 21px;
		text-transform: uppercase;
		letter-spacing: 0.14px;
		color: $text-blue!important;
		display: block;
		text-align: center;
		margin: auto;
		@include transition3;
		&:hover {
			text-decoration: none;
			color: #7dd0e2!important;
		}
	}
}

.range-container {
	padding-top: 55px;
	padding-bottom: 38px;
	&_m30 {
		margin: 30px 0;
	}
	&-title {
		font-size: 17px;
		color: #000000;
		line-height: 1.3;
	}
	&-output {
		font-size: 38px;
		color: #000000;
		padding-top: 5px;
    	//padding-bottom: 7px;
	}
	&-description {
		color: #c7c7c7;
		font-size: 12px;
		font-weight: 400;
		max-width: 90%;
		line-height: 1.3;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 45px;
		padding-bottom: 20px;
		&-title {
			font-size: 15px;
		}
		&-output {
			font-size: 34px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 30px;
		&-title {
			font-size: 13px;
		}
		&-output {
			font-size: 30px;
		}
	}
		
	.custom-range {
	  $custom-range-thumb-width: 23px;
	  $custom-range-thumb-height: 23px;
	  $custom-range-thumb-bg: $accent-color;
	  $custom-range-thumb-border: 5px;
	  $custom-range-track-height: 3px;
	  $custom-range-track-bg: $accent-color;
	  $custom-range-thumb-box-shadow: 0 0 20px rgba(56, 53, 54, 0.2);
	  
	  padding-top: 10px;
	  padding-bottom: 30px;
	  @include media-breakpoint-down(sm) {
		  padding-bottom: 15px;
	  }
	  width: 100%;
	  padding-left: 0; // Firefox specific
	  background-color: transparent;
	  appearance: none;

	  &:focus {
	    outline: none;
	  }

	  &::-moz-focus-outer {
	    border: 0;
	  }

	  &::-webkit-slider-thumb  {
	    width: $custom-range-thumb-width;
	    height: $custom-range-thumb-height;
	    margin-top: -($custom-range-thumb-width * .5); // Webkit specific?
	    @include gradient-bg($custom-range-thumb-bg);
	    //border: $custom-range-thumb-border;
	    border: 5px solid white;
	    @include border-radius($custom-range-thumb-border-radius);
	    box-shadow: $custom-range-thumb-box-shadow;
	    appearance: none;

	    &:focus {
	      outline: none;
	      box-shadow: $custom-range-thumb-focus-box-shadow; // No mixin for focus accessibility
	    }

	    &:active {
	      @include gradient-bg($custom-range-thumb-active-bg);
	    }
	  }

	  &::-webkit-slider-runnable-track {
	    width: $custom-range-track-width;
	    height: $custom-range-track-height;
	    color: transparent; // Why?
	    cursor: $custom-range-track-cursor;
	    background-color: $custom-range-track-bg;
	    //border-color: transparent;
	    border-color: white;
	    @include border-radius($custom-range-track-border-radius);
	    @include box-shadow($custom-range-track-box-shadow);
	  }

	  &::-moz-range-thumb {
	    width: 15px;
	    height: 15px;
	    @include gradient-bg($custom-range-thumb-bg);
	    //border: $custom-range-thumb-border;
	    border: 5px solid white;
	    @include border-radius($custom-range-thumb-border-radius);
	    box-shadow: $custom-range-thumb-box-shadow;
	    appearance: none;

	    &:focus {
	      outline: none;
	      box-shadow: $custom-range-thumb-focus-box-shadow; // No mixin for focus accessibility
	    }

	    &:active {
	      @include gradient-bg($custom-range-thumb-active-bg);
	    }
	  }

	  &::-moz-range-track {
	    width: $custom-range-track-width;
	    height: 3px;
	    color: transparent;
	    cursor: $custom-range-track-cursor;
	    background-color: $custom-range-track-bg;
	    //border-color: transparent; // Firefox specific?
	    border: 5px solid white;
	    @include border-radius($custom-range-track-border-radius);
	    box-shadow: $custom-range-track-box-shadow;
	  }

	  &::-ms-thumb {
		margin-top: -4px;
	    width: 15px;
	    height: 15px;
	    @include gradient-bg($custom-range-thumb-bg);
	    //border: $custom-range-thumb-border;
	    border: 5px solid white;
	    @include border-radius($custom-range-thumb-border-radius);
	    box-shadow: $custom-range-thumb-box-shadow;
	    appearance: none;

	    &:focus {
	      outline: none;
	      box-shadow: $custom-range-thumb-focus-box-shadow; // No mixin for focus accessibility
	    }

	    &:active {
	      @include gradient-bg($custom-range-thumb-active-bg);
	    }
	  }

	  &::-ms-track {
	    width: $custom-range-track-width;
	    height: 3px;
	    color: transparent;
	    cursor: $custom-range-track-cursor;
	    background-color: transparent;
	    border-color: transparent;
	    border-width: ($custom-range-thumb-height * .5);
	    @include box-shadow($custom-range-track-box-shadow);
	  }

	  &::-ms-fill-lower {
	    background-color: $custom-range-track-bg;
	    @include border-radius($custom-range-track-border-radius);
	  }

	  &::-ms-fill-upper {
	    margin-right: 15px; // arbitrary?
	    background-color: $custom-range-track-bg;
	    @include border-radius($custom-range-track-border-radius);
	  }
	}
}

/*
	Product Table
*/

.product-table {
	$col-1: 22.5%;
	$col: 16.2%;
	$mr-col: 2.8%;

	padding-top: 87px;
	padding-bottom: 60px;
	font-family: $ff-Raleway;
	&-header,
	.table-row {
		display: flex;
		align-items: center;
	}
	&-header {
		color: black;
		min-height: 116px;
		padding-bottom: 6px;
		.table-col {
			flex-direction: column;
		}
		.content-wrap {
			display: flex;
			align-items: flex-start;
			width: 100%;
			justify-content: flex-end;
			padding-right: 25%;
		}
	}
	&-header-title {
		font-family: $ff-Raleway;
		font-size: 17px;
		font-weight: 700;
		letter-spacing: 1.7px;
		text-align: left;
		width: 100%;
		text-transform: uppercase;
	}
	&-body {
		position: relative;
		.pseudo-box-shadow {
			position: absolute;
			top: 0;
			bottom: 0;
			width: $col;
			background-color: white;
			box-shadow: 0 0 20px rgba(53, 56, 56, 0.2);
			//z-index: 5;
			&-1 {
				left: $col-1;
			}
			&-2 {
				left: $col-1 + $col +$mr-col;
			}
			&-3 {
				left: $col-1 + $col +$mr-col + $col +$mr-col;
			}
			&-4 {
				left: $col-1 + $col +$mr-col + $col +$mr-col + $col +$mr-col;
			}
		}
	}
	&-footer {
		&-text {
			flex: 1;
			color: #9e9e9e;
			font-size: 13px;
			font-weight: 400;
			text-align: center;
			align-self: center;
		}
	}
	.text-lowercase {
		margin-top: 6px;
		padding-right: 5px;
		text-transform: lowercase;
		font-size: 18px;
		font-weight: 700;
		font-family: $ff-Roboto;
		line-height: 1;
	}
	.text-number {
		font-size: 52px;
		font-weight: 700;
		font-family: $ff-Roboto;
		line-height: 1;
	}
	.table-row {
		position: relative;
		display: flex;
		align-items: stretch;		
		min-height: 80px;
		z-index: 4;
		&:not(:first-child) {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 16px;
				height: 1px;
				background-color: #d7d7d7;
			}
		}
	}
	.table-col {
		position: relative;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
		display: flex;
		align-items: center;
		z-index: 7;
		&-1 {
			flex: 0 0 $col-1;
		}
		&-2,
		&-3,
		&-4,
		&-5 {
			justify-content: center;
			flex: 0 0 $col;
			background-color: white;
			margin-right: $mr-col;
			font-weight: 700;
			color: black;
			font-size: 18px;
			&.success {
				color: $bg-yellow;
			}
		}
	}
	.title {
		font-size: 16px;
		color: black;
	}
	.subtitle {
		font-size: 13px;
		color: #9e9e9e;
	}
	.success {
		color: $bg-yellow;
		&:before {
			content: '\f00c';
			font-family: $ff-FontAwesome;
			margin-right: 3px;
		}
	}
}

/*
	Tax Calculator
*/
.tax-calculator {
	position: relative;
	background-color: $bg-yellow;
	padding-top: 67px;
	//padding-bottom: 75px;
	.bg-icon {
		position: relative;
		& > div {
			position: relative;
			z-index: 4;
		}
		&:after {
			content: '';
			position: absolute;
			right: -40px;
			width: 100%;
			height: 100%;
			background-image: url('#{$img-path}/bg-icon-tax-calculator.png');
			background-repeat: no-repeat;
			background-size: auto;
			background-position: right top;
			z-index: 3;
		}
	}
	&-header {
		text-align: center;
		.h1-title {
			color: white;
			line-height: 1.2;
		}
		.subtitle {
			margin: 0 auto;
			padding-top: 30px;
			padding-bottom: 40px;
			max-width: 562px;
			font-size: 15px;
		}
	}
	&-body {
		$top: 20px;
		$bottom: 20px;
		$left: 30px;
		$right: 30px;
		background-color: white;
		margin-left: 10px;
		margin-right: 10px;
		border-radius: 5px;
		box-shadow: 0 0 20px rgba(53, 56, 56, 0.2);
		.form-row {
			& >  [class*="col-"] {
				padding-right: 18px;
				padding-left: 4px;
			}
			& + hr {
				margin-top: 6px;
				margin-bottom: 23px;
			}			
		}
		.form-control {
			text-align: right;
			&:focus {
			    box-shadow: none;
			    border: 1px solid #2dd8fb;
			}
		}
		.form-group {
			margin-bottom: 12px;
			label {
				font-size: 12px;
				font-family: $ff-Open-Sans;
				text-transform: uppercase;
				color: #4a4a4a;
				margin-bottom: 0;
			}
			input,
			.input-group-text {
				font-size: 18px;
				color: #010101;
			}
			input {
				&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			    color: #010101;
			    opacity: 1; /* Firefox */
				}

				&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				    color: #010101;
				}

				&::-ms-input-placeholder { /* Microsoft Edge */
				    color: #010101;
				}
			}
			.input-group.pseudo-select {
				position: relative;
				.form-control {
					padding-right: 45px;
				}				
				&:before {
					top: 56%;
				    right: 8px;
				    position: absolute;
				    content: '';
				    width: 0;
				    height: 0;
				    border-left: 5px solid transparent;
				    border-right: 5px solid transparent;
				    border-top: 10px solid #2dd8fb;
				    transform: translateY(-50%);
				    z-index: 555;
				}
			}

		}
		.input-data {
			padding: $top 0 $bottom $left;
		}
		.output-data {
			border-bottom-right-radius: 5px;
			border-top-right-radius: 5px;
			box-shadow: inset 0 0 20px rgba(53, 56, 56, 0.2);
			background-color: #f9f9f9;
			padding: $top $right $bottom $left;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			&-row {
				display: flex;
				flex: 1;
				font-size: 18px;
				&:first-child {
					padding-top: 20px;
				}
				.price-data {
					color: $text-blue;
					flex: 0 0 50px;
					text-align: right;
					font-weight: 700;
					padding-right: 15px;
				}
				.text-data {
					flex: 1;
					padding-left: 8px;
					color: black;
				}
			}
			&-fullprice {
				margin-top: auto;
				margin-bottom: 0;
				padding-top: 5px;
				.type {
					display: block;
					margin-bottom: -5px;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 400;
					line-height: 1;
				}
				.price {
					font-size: 52px;
					font-weight: 700;
					color: black;
					line-height: 1;
				}
				.description {
					font-size: 14px;
					color: #a6a6a6;
					font-weight: 400;
				}
			}
			hr {
				margin-top: 10px;
			    margin-bottom: 10px;
			    border-top: 1px solid #dedede;
			    //background-color: #dedede;
			    //height: 1px;
			    width: 100%;
			}
		}
		.btn-wrapper {
			padding-top: 10px;
			padding-right: 14px;
		}
		.btn {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 14px;
			letter-spacing: 1px;
			padding-top: 10px;
			padding-bottom: 8px;
			letter-spacing: 0.7px;
		}
	}
	&-footer {
		padding-top: 50px;
		text-align: center;
		.btn-wrapper {
			margin-bottom: -25px;
			.btn {
				font-size: 18px;
				font-family: $ff-Raleway;
				text-shadow: 0 0 10px rgba(40, 43, 44, 0.2);
				box-shadow: 0 0 40px rgba(53, 56, 56, 0.2);
				font-weight: 700;
				text-transform: uppercase;
				border-radius: 27px;
				padding-left: 30px;
				padding-right: 30px;
				padding-top: 15px;
				padding-bottom: 13px;
			}
		}
	}
}