//@import 'utils/mixins';
.partnerial-wrap {
	background-image: url('#{$img-path}/bg-partnerial-wrap.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-color: white;
	padding-top: 17px;
}
.partnerial-head {
	//min-height: 700px;
	padding-bottom: 40px;
	background-color: #fff;
}

#homepagev3 .partnerial-item {
	margin: 0;
	&:not(:first-child) {
		border-top: 1px solid #eaeaea;
	}
	&-nav {
		align-items: center;
		height: 110px;
		margin: 0;
	}
}
.partnerial-item {
	text-align: center;
	margin: 65px 0;
	ul {
		margin-bottom: 0;
	}
	&-title {
		font-family: $ff-Raleway;
		font-size: 12px;
		line-height: 21px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1.2px;
		color: #4c4c4c;
	}
	&-nav {
		display: flex;
		justify-content: center;
		margin: 22px 0;
		// &_grey {
		// 	li {
		// 		a {
		// 			img {
		// 				filter: grayscale(100%);
		// 			}
		// 			&:hover {
		// 				img {
		// 					filter: none;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		li {
			list-style: none;
			display: flex;
			align-items: center;
			margin: 0 35px;
			a {
				display: block;
				img {
					@include responImg
				}
			}
		}
	}
}

.full {
	display: flex;
	position: relative;
}

.full-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 50%;
	height: 100%;
	background-color: #f9c21b;
}

.full-img {
	position: absolute;
	right: 0;
	top: 0;
	width: 50%;
	height: 100%;
	background: url("#{$img-path}/full-img.png") no-repeat center;
	background-size: cover;
	img {
		display: block;
		@include responImg;
		width: 100%;
	}
	@include media-breakpoint-up(md) {
		img {
			display: none;			
		}
	}
}

.full-info {
	color: #fff;
	padding: 70px 0;
	min-height: 420px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&-title {
		font-size: 22px;
		font-weight: 700;
		font-family: $ff-Raleway;
	}
	&-text {
		font-size: 14px;
		line-height: 21px;
		display: block;
		margin: 10px 0;
	}
	&-link {
		display: block;
		text-transform: uppercase;
		color: #fff;
		font-size: 14px;
		line-height: 14px;
		font-weight: 700;
		letter-spacing: 0.7px;
		margin-top: 20px;
		transition: all 0.3s ease-out;
		&:hover {
			text-decoration: none;
			color: #000;
		}
	}
}

@include media-breakpoint-down(sm) {
	.full {
		display: block;
	}
	.full-info {
		min-height: auto;
		padding: 35px;
	}
	.full-img {
		position: relative;
		background-image: none;
	}
	.full-background,
	.full-img {
		width: 100%;
	}
}
