//@import 'utils/mixins';

/*
	Banners
*/
@mixin bg-img($url, $type) {
	background-image: url($url);
	background-size: $type;
	background-repeat: no-repeat;
}

.header-banner {
	&.banner-vidinis-1 {
		position: relative;
		background-color: white;
		height: 509px;
		// @include bg-img('img/banner-vidinis-1.jpg', 39%);
		// @media (max-width: 1281px)	{
		// 	@include bg-img('img/banner-vidinis-1.jpg', 30%);
		// 	//display: none;
		// }
		.img-wrapper {
			overflow: hidden;
			height: 509px;
			margin-left: -15px;
			margin-top: -70px;
			margin-bottom: -53px;
			margin-right: 38px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.h1-title {
			padding-bottom: 25px;
		}
		@include media-breakpoint-down(lg) {
			height: auto;
			.img-wrapper {
				height: auto;
				margin-right: 10px;
			}
		}
		@media (max-width: 1030px) {

		}
		@include media-breakpoint-down(md) {
			padding-top: 40px;
			padding-bottom: 40px;
			.img-wrapper {
				margin-right: -15px;
				margin-top: -40px;
				margin-bottom: 30px;
				height: 200px;
			}
			.header-banner-content-wrapper {
				max-width: 720px;
				margin: 0 auto;
			}
			.header-banner-description {
				font-size: 14px;
			}
			.h1-title {
				font-size: 24px;
				padding-bottom: 15px;
			}
			.button-wrapper {
				padding-top: 5px;
			}
		}
		@include media-breakpoint-down(sm) {
			.header-banner-content-wrapper {
				padding-left: 10px;
				padding-right: 10px;
				max-width: 540px;
			}
		}
		@include media-breakpoint-down(xs) {
			padding-bottom: 20px;
			.header-banner-content-wrapper {
				padding-left: 0;
				padding-right: 0;
			}
			.button-wrapper {
				padding-top: 0;
				display: flex;
				flex-wrap: wrap;
			}
			.btn {
				margin-top: 5px;
				font-size: 12px;
				padding-top: 7px;
				&:not(:last-child) {
					margin-right: 5px;
				}
			}
		}
	}
}

.banner-isbandyti {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 530px;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: .5;
		background-color: $bg-blue;
	}
	img {
		@include responImg;
		margin: 0 auto;
	}
}

.header-banner {
	padding-top: 70px;
	padding-bottom: 53px;
	height: 509px;
	&-content-wrapper {
		//padding-left: 47px;
		padding-left: 5px;
	}
	&-description {
		font-size: 17px;
		max-width: 566px;
	}
	.h1-title {
		font-family: $ff-Raleway;
		letter-spacing: 7px;
		padding-bottom: 43px;
	}
	.button-wrapper {
		padding-top: 39px;
	}
	.btn {
		min-width: 180px;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 2;
		letter-spacing: 0.7px;
		box-shadow: 0 0 20px rgba(53, 56, 56, 0.2);
		&:not(:last-child) {
			margin-right: 22px;
		}
	}
	.text-small {
		color: #ababab;
		padding-top: 14px;
	}
	@include media-breakpoint-down(lg) {
		&-description {
			font-size: 15px;
		}
		.btn {
			min-width: 140px;
		}
	}
	@include media-breakpoint-down(md) {
		&.banner-isbandyti {
			padding-top: 40px;
			padding-bottom: 20px;
			min-height: 420px;
			height: 420px;
		}
	}
	@include media-breakpoint-down(sm) {
		&.banner-isbandyti {
			.container { 
				min-width: 100%;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		&.banner-isbandyti {
			min-height: 300px;
			height: 300px;
		}
	}
	@media (max-width: 420px) {
		&.banner-isbandyti {
			min-height: 200px;
			height: 200px;
		}
	}
	@media (max-width: 365px) {
		&.banner-isbandyti {
			min-height: 180px;
			height: 180px;
		}
	}
}

.header-slider {
	max-height: 770px;
	position: relative;
	color: white;
	background-repeat: no-repeat;
	background-size: cover;
	&_first {
		.logo-box {
			max-width: 100px;
			img {
				@include responImg;
			}
		}
	}
	.container {
		&:after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-image: url('#{$img-path}/bg-icon-header-slider.png');
			background-position: center -300px;
			// width: 611px;
			// height: 611px;
			// width: 32vw;
		  //   height: 10vh;
			background-repeat: no-repeat;
			background-size: 590px;
			z-index: 4;
		}
	}
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;	
		opacity: .7;
		z-index: 3;
	}
	&.bg-blue {
		&:before {
			background-color: $bg-blue;
		}
	}

	&-header,
	&-body {
		position: relative;
		z-index: 99;
	}

	&-header {
		height: 190px;
		display: flex;
		align-items: center;
	}
	.carousel {
		padding-bottom: 0;
		.carousel-item {
			&-content {
				max-width: 40%;
				padding-left: 100px;
				padding-top: 140px;
				.text {
					&-small {
					    font-size: 10px;
					    font-weight: 400;
					    text-transform: uppercase;
					    letter-spacing: 2px;
					    display: block;
					    padding-bottom: 30px;
					}
					&-title {
					    font-size: 30px;
					    font-family: $ff-Raleway;
					    font-weight: 700;
					    text-transform: uppercase;
					    letter-spacing: 6px;
					    //margin: 30px 0 50px 0;
					    padding-bottom: 40px;
					}
					&-text {
					    font-size: 15px;
					    font-weight: 400;
					    line-height: 24px;
					    display: block;
					    padding-bottom: 38px;
					    margin-bottom: 0;
					}

				}
			}
		}
		.btn {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 0.7px;
			padding-left: 16px;
			padding-right: 16px;
			padding-top: 9px;
			padding-bottom: 9px;
			margin-right: 4px;
			letter-spacing: 0.7px;	
			&.btn-link {
				color: white;
				&:hover,
				&:focus,
				&.active {
					text-decoration: none;
					color: inherit;
				}
			}
		}
		.button-wrapper {
			display: flex;
			align-items: center;
		}
		.carousel-item-link {
			margin-top: 0;
			margin-left: 25px;
		}
	}
	.carousel-indicators {
		bottom: auto;
		left: auto;
		top: 0;
		right: 0;
		justify-content: flex-end;
		margin-right: 0;
		li {
			border-color: white;
			background-color: transparent;
			&.active {
				background-color: white;
			}
		}
	}

	.carousel-inner {		
		.carousel-item {			
			&.h-650	{
				height: 650px;
			}
		}
		.img-wrapper {
			position: absolute;
			bottom: 0;
			right: 0;
			max-width: 70%;
			img {
				@include responImg;
			}
			&_b45 {
				bottom: 45px;
			}
		}
	}
	.carousel-control-prev-icon,
	.carousel-control-next-icon {
		background-image: none;
		width: 50px;
		height: 50px;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.carousel-control-next {
		justify-content: flex-end;
	}
	.carousel-control-next-icon {
		background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMjg0LjkzNSAyODQuOTM2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyODQuOTM1IDI4NC45MzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMjIyLjcwMSwxMzUuOUw4OS42NTIsMi44NTdDODcuNzQ4LDAuOTU1LDg1LjU1NywwLDgzLjA4NCwwYy0yLjQ3NCwwLTQuNjY0LDAuOTU1LTYuNTY3LDIuODU3TDYyLjI0NCwxNy4xMzMgICBjLTEuOTA2LDEuOTAzLTIuODU1LDQuMDg5LTIuODU1LDYuNTY3YzAsMi40NzgsMC45NDksNC42NjQsMi44NTUsNi41NjdsMTEyLjIwNCwxMTIuMjA0TDYyLjI0NCwyNTQuNjc3ICAgYy0xLjkwNiwxLjkwMy0yLjg1NSw0LjA5My0yLjg1NSw2LjU2NGMwLDIuNDc3LDAuOTQ5LDQuNjY3LDIuODU1LDYuNTdsMTQuMjc0LDE0LjI3MWMxLjkwMywxLjkwNSw0LjA5MywyLjg1NCw2LjU2NywyLjg1NCAgIGMyLjQ3MywwLDQuNjYzLTAuOTUxLDYuNTY3LTIuODU0bDEzMy4wNDItMTMzLjA0NGMxLjkwMi0xLjkwMiwyLjg1NC00LjA5MywyLjg1NC02LjU2N1MyMjQuNjAzLDEzNy44MDcsMjIyLjcwMSwxMzUuOXoiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
	}
	.carousel-control-prev {
		justify-content: flex-start;
	}
	.carousel-control-prev-icon {
		justify-content: flex-start;
		background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMjg0LjkzNSAyODQuOTM2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyODQuOTM1IDI4NC45MzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMTEwLjQ4OCwxNDIuNDY4TDIyMi42OTQsMzAuMjY0YzEuOTAyLTEuOTAzLDIuODU0LTQuMDkzLDIuODU0LTYuNTY3YzAtMi40NzQtMC45NTEtNC42NjQtMi44NTQtNi41NjNMMjA4LjQxNywyLjg1NyAgIEMyMDYuNTEzLDAuOTU1LDIwNC4zMjQsMCwyMDEuODU2LDBjLTIuNDc1LDAtNC42NjQsMC45NTUtNi41NjcsMi44NTdMNjIuMjQsMTM1LjljLTEuOTAzLDEuOTAzLTIuODUyLDQuMDkzLTIuODUyLDYuNTY3ICAgYzAsMi40NzUsMC45NDksNC42NjQsMi44NTIsNi41NjdsMTMzLjA0MiwxMzMuMDQzYzEuOTA2LDEuOTA2LDQuMDk3LDIuODU3LDYuNTcxLDIuODU3YzIuNDcxLDAsNC42Ni0wLjk1MSw2LjU2My0yLjg1NyAgIGwxNC4yNzctMTQuMjY3YzEuOTAyLTEuOTAzLDIuODUxLTQuMDk0LDIuODUxLTYuNTdjMC0yLjQ3Mi0wLjk0OC00LjY2MS0yLjg1MS02LjU2NEwxMTAuNDg4LDE0Mi40Njh6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
	}
}

.brand-section {
	padding-top: 95px;
	//padding-bottom: 56px;
	overflow: hidden;
	.container {
		position: relative;
		z-index: 2;
		padding-bottom: 56px;
	}
	.bg-logo {
		position: absolute;
		top: 0;
		//bottom: -(841/2) + 0px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
	}
	//background-image: url('#{$img-path}/bg-brand-section.png');
	// background-repeat: no-repeat;
	// background-position: center bottom;
	// background-size: 50%;
	.nav {
		position: relative;
		padding-top: 75px;
		padding-bottom: 140px;
		margin-left: -80px;
		margin-right: -80px;
		justify-content: space-between;
		z-index: 2;
		.nav-item {
			display: flex;
			align-items: center;
			&.center {
				padding-right: 30px;
			}
		}
		img {
			&:not(.multicolor) {
				filter: grayscale(100%);
				opacity: 0.5;
			}
		}
	}
	&-text {
		position: relative;
		max-width: 245px;
		margin: 0 auto;
		text-align: center;
		z-index: 2;
		&-title {
			font-size: 19px;
			font-weight: 700;
			color: black;
			padding-bottom: 10px;
		}
		&-text {
			font-size: 13px;
			font-weight: 400;
			color: #565656;
		}
	}
}