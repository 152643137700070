//@import 'utils/mixins';

.frame {
	text-align: center;
	padding: 50px 0 20px 0;
	&-title {
		font-family: $ff-Raleway;
		font-size: 24px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2.4px;
		color: #010101;
		max-width: 600px;
		margin: auto;
	}
	&-text {
		color: #4a4a4a;
		font-size: 15px;
		font-weight: 400;
		line-height: 24px;
		display: block;
		max-width: 600px;
		margin: 15px auto;
	}
	.iframe-responsive {
		margin: 30px auto;
		box-shadow: 0 0 30px rgba(40, 43, 44, 0.5);
		max-width: 610px;
	}
	.btn {
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
		text-transform: uppercase;
		letter-spacing: 0.7px;
		padding: 8px 15px;
		margin-top: 20px;
	}
}

.carousel-wrap {
	min-height: 795px;
	background-color: #000;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	color: #fff;
	text-align: center;
	padding: 70px 0 50px 0;
	&-title {
		color: #ffffff;
		font-family: $ff-Raleway;
		font-size: 30px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 6px;
		margin: 25px 0 15px 0;
	}
	&-subtitle {
		color: #ffffff;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		display: block;
		margin: 15px 0;
		b {
			font-weight: 700;
		}
	}
}

.carousel-item {
	&-title {
		font-family: $ff-Lora;
		font-size: 48px;
		font-style: italic;
		margin: 10px 0 20px 0;
	}
	&-text {
		font-size: 10px;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 2px;
		display: block;
		margin: 20px 0;
	}
	&-link {
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
		text-transform: uppercase;
		color: #fff;
		margin-top: 60px;
		display: block;
		&:hover,
		&:focus {
			text-decoration: none;
			color: inherit;
		}
	}
}

.carousel-wrap {
	.carousel {
		padding: 130px 0 180px 0!important;
		&-indicators {
			bottom: 0;
			li {
				border-color: white;
				background-color: transparent;
				&.active {
					background-color: white;
				}
			}
		}
		.carousel-control-prev-icon,
		.carousel-control-next-icon {
			background-image: none;
			width: 28px;
			height: 28px;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.carousel-control-next {
			justify-content: flex-end;
			width: 28px;
		}
		.carousel-control-next-icon {
			background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMjg0LjkzNSAyODQuOTM2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyODQuOTM1IDI4NC45MzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMjIyLjcwMSwxMzUuOUw4OS42NTIsMi44NTdDODcuNzQ4LDAuOTU1LDg1LjU1NywwLDgzLjA4NCwwYy0yLjQ3NCwwLTQuNjY0LDAuOTU1LTYuNTY3LDIuODU3TDYyLjI0NCwxNy4xMzMgICBjLTEuOTA2LDEuOTAzLTIuODU1LDQuMDg5LTIuODU1LDYuNTY3YzAsMi40NzgsMC45NDksNC42NjQsMi44NTUsNi41NjdsMTEyLjIwNCwxMTIuMjA0TDYyLjI0NCwyNTQuNjc3ICAgYy0xLjkwNiwxLjkwMy0yLjg1NSw0LjA5My0yLjg1NSw2LjU2NGMwLDIuNDc3LDAuOTQ5LDQuNjY3LDIuODU1LDYuNTdsMTQuMjc0LDE0LjI3MWMxLjkwMywxLjkwNSw0LjA5MywyLjg1NCw2LjU2NywyLjg1NCAgIGMyLjQ3MywwLDQuNjYzLTAuOTUxLDYuNTY3LTIuODU0bDEzMy4wNDItMTMzLjA0NGMxLjkwMi0xLjkwMiwyLjg1NC00LjA5MywyLjg1NC02LjU2N1MyMjQuNjAzLDEzNy44MDcsMjIyLjcwMSwxMzUuOXoiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
		}
		.carousel-control-prev {
			justify-content: flex-start;
			width: 28px;
		}
		.carousel-control-prev-icon {
			justify-content: flex-start;
			background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMjg0LjkzNSAyODQuOTM2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyODQuOTM1IDI4NC45MzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMTEwLjQ4OCwxNDIuNDY4TDIyMi42OTQsMzAuMjY0YzEuOTAyLTEuOTAzLDIuODU0LTQuMDkzLDIuODU0LTYuNTY3YzAtMi40NzQtMC45NTEtNC42NjQtMi44NTQtNi41NjNMMjA4LjQxNywyLjg1NyAgIEMyMDYuNTEzLDAuOTU1LDIwNC4zMjQsMCwyMDEuODU2LDBjLTIuNDc1LDAtNC42NjQsMC45NTUtNi41NjcsMi44NTdMNjIuMjQsMTM1LjljLTEuOTAzLDEuOTAzLTIuODUyLDQuMDkzLTIuODUyLDYuNTY3ICAgYzAsMi40NzUsMC45NDksNC42NjQsMi44NTIsNi41NjdsMTMzLjA0MiwxMzMuMDQzYzEuOTA2LDEuOTA2LDQuMDk3LDIuODU3LDYuNTcxLDIuODU3YzIuNDcxLDAsNC42Ni0wLjk1MSw2LjU2My0yLjg1NyAgIGwxNC4yNzctMTQuMjY3YzEuOTAyLTEuOTAzLDIuODUxLTQuMDk0LDIuODUxLTYuNTdjMC0yLjQ3Mi0wLjk0OC00LjY2MS0yLjg1MS02LjU2NEwxMTAuNDg4LDE0Mi40Njh6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
		}
	}
}

.state-block {
	min-height: 770px;
	position: relative;
	display: flex;
	.main-bg {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;
	}
}

.state-wrap {
	display: flex;
	align-items: center;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		background-image: url("#{$img-path}/star1.png");
		background-repeat: no-repeat;
		background-position: right;
		width: 100%;
		height: 100%;
	}
}

.state {
	position: relative;
	//padding: 215px 0 140px 0;
	display: flex;
	align-items: center;
	&-logo {
		width: 110px;
		display: block;
		position: absolute;
		top: 75px;
		left: 0;
		img {
			@include responImg;
		}
	}
	&-text {
		width: 44%;
		padding: 0 70px 0 85px;
		position: relative;
		z-index: 1;
		&-title {
			color: #4b4b4b;
			font-family: $ff-Raleway;
			font-size: 30px;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 6px;
			margin-bottom: 20px;
		}
		&-text {
			color: #a2a2a2;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
			display: block;
			margin: 15px 0;
		}
		&-link {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700!important;
			letter-spacing: 0.7px;
			margin-top: 30px;
		}
	}
	&-iframe {
		width: 50%;
	}
}

#home-2 {
	.header-slider {
		margin-bottom: 65px;
	}
}
#homepagev3 {
	.header-slider {
		margin-bottom: 10px;
		.carousel-inner {
			.img-wrapper {
				bottom: 32px;
			}
		}
	}
	.frame {
		padding-top: 45px;
		padding-bottom: 30px;
	}
	.frame-title {
		font-size: 30px;
	}
	footer {
		padding-top: 25px;
	}
}