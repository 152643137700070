#prisijungimas {
    .content-box {
        .content-header {
            padding-bottom: 49px;
        }
        .content-title,
        .content-subtitle {
            color: white;
        }
    } 
    .form-container-header {
        .form-title {
            padding-bottom: 30px;
        }
    }
}