//@import 'utils/mixins';

/*
	Nauda Page
*/

.bg-uzsklanda {
	position: relative;
	background-image: url("#{$img-path}/bg-uzsklanda.jpg");
    background-size: cover;
    background-repeat: no-repeat;
	&:before {
		position: absolute;
	    content: '';
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: black;
	    opacity: 0.55;
	    z-index: -1;
	}
}

#uzsklanda {
	color: white;
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
		.logo-box {
			padding-top: 120px;
			text-align: center;
			img {
				max-width: 211px;
			}
		}
		.content-box {
			.title,
			.subtitle {
				text-align: center;
			}
			.title {
				font-family: $ff-Raleway;
				font-size: 72px;
                font-weight: 300;
                padding-top: 188px;
                line-height: 1.38;
			}
			.subtitle {
				font-size: 28px;
				//padding-top: 30px;
			}
		}
		.form-wrapper {
			text-align: center;
			padding-top: 50px;
			padding-bottom: 56px;
			.icon-envelope-alt {
				font-size: 19px;
			}
			.form-inline {
				padding-top: 55px;;
				justify-content: center;
			}
			.form-group {
				flex: 0 0 260px;
				max-width: 260px;
			}
			.form-control {
				font-size: 16px;
				padding-top: 8px;
				padding-bottom: 8px;
				font-weight: 400;
				color: #959595;
				width: 100%;
				margin-right: 8px;
				&:focus {
					border: 1px solid #c9c9c9;
					box-shadow: 0 0 0 5px rgba(209, 209, 209, 0.2);
				}
			}
			.form-wrapper_text {
				padding-top: 18px;
				font-size: 14px;
				font-weight: normal;
				max-width: 500px;
				margin: 0 auto;
			}
			.btn {
				text-transform: uppercase;
				font-weight: bold;
				font-size: 14px;
				padding: 9.5px 10px 9.5px 10px;
				min-width: 177px;
				text-align: center;
				letter-spacing: 0.7px;
				box-shadow: 0 0 20px rgba(53, 56, 56, 0.2);
			}
		}
	}
	footer {
		padding-top: 0;
		.footer-bottom-row {
			.mr-auto,
			.ml-auto {
				text-transform: uppercase;
				font-weight: 400;
				letter-spacing: 2px;
			}
		}
	}
}