//@import 'utils/mixins';

.blog {
	&-nav {
		display: flex;
		margin: 35px 0;
		padding: 0;
		justify-content: center;
		li {
			list-style: none;
			margin: 0 20px;
			a {
				font-size: 14px;
				display: block;
				text-transform: uppercase;
				color: #d2d2d2;
				font-weight: 700;
				letter-spacing: 0.7px;
				@include transition3;
				&:hover {
					text-decoration: none;
					color: $text-blue;
				}
			}
			&.active {
				a {
					color: $text-blue;
				}
			}
		}
	}
	&-wrap {
		padding: 10px 0;
		& + .pagination-nav {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
}

.blog-item {
	background-color: #fff;
	margin: 25px 0;
	&-img {
		height: 100%;
		min-height: 250px;
		position: relative;
    	overflow: hidden;
		img {
			min-height: 100%;
			min-width: 100%;
			max-width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
	}
	&-info {
		padding: 10px 20px 10px 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&-small {
		font-size: 11px;
		color: #c1c1c1;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.55px;
		display: block;
		margin: 10px 0;
	}
	&-title {
		font-size: 22px;
		font-weight: 700;
		color: #4c4c4c;
		font-family: $ff-Raleway;
		letter-spacing: 0.55px;
		margin: 10px 0;
	}
	&-text {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		display: block;
		margin: 10px 0;
	}
	&-link {
		font-size: 14px;
		line-height: 24px;
		font-weight: 700;
		letter-spacing: 0.7px;
		text-transform: uppercase;
		color: $text-blue;
		margin: 10px 0;
		display: block;
	}
}

.pagination-nav {
	padding: 0;
	display: flex;
	justify-content: center;
	margin: 40px 0;
	li {
		list-style: none;
		margin: 0 6px;
		a {
			width: 33px;
			height: 33px;
			@include flexCenter;
			border-radius: 50%;
			background-color: #c9c9c9;
			color: #fff;
			font-weight: 700;
			font-size: 14px;
			@include transition3;
			&:hover {
				background-color: $text-blue;
				text-decoration: none;
			}
		}
		&.active {
			a {
				background-color: $text-blue;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.pagination-nav {
		li {
			margin: 0 3px;
			a {
				width: 30px;
				height: 30px;
				font-size: 13px;
			}
		}
	}
}