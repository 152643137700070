@import 'utils/mixins';

.back-img {
	background-size: cover!important;
	display: flex;
	align-items: center;
	color: #fff;
	&_2 {
		background: black url("#{$img-path}/back-img2.png") no-repeat center;
	}
}

.center-style {
	min-height: 411px;
	text-align: center;
	&-title {
		font-family: $ff-Raleway;
		font-size: 30px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 6px;
		max-width: 750px;
		margin: auto;
	}
	&-link {
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
		text-transform: uppercase;
		letter-spacing: 0.7px;
		color: #fff;
		display: block;
		margin-top: 40px;
		&:hover,
		&:focus {
			color: inherit;
		}
	}
}

.between-style {
	min-height: 226px;
	background: black url("#{$img-path}/office.png") no-repeat center;
	&.bg-green {
		background: #44da64;
		.btn {
			color: #44da64;
		}
	}
	&-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.btn {
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 700;
			height: 58px;
			padding: 10px 30px;
			font-family: $ff-Raleway;
			box-shadow: 0 0 40px #353838;
			text-shadow: 0 0 10px rgba(40, 43, 44, 0.2);
		}
	}
}

.between-style-info {
	color: #fff;
	&-title {
		text-transform: uppercase;
		letter-spacing: 6px;
		font-size: 30px;
		font-weight: 700;
		font-family: $ff-Raleway;
	}
	&-text {
		font-size: 15px;
		line-height: 24px;
		font-weight: 400;
		display: block;
	}
}

.comments-item {
	text-align: center;
	padding: 50px 70px;
	&-img {
		width: 80px;
		height: 80px;
		overflow: hidden;
		border-radius: 50%;
		margin: auto;
		margin-bottom: 10px;
		img {
			@include responImg
		}
	}
	&-title {
		font-size: 10px;
		font-weight: 400;
		letter-spacing: 2px;
		color: #b7b7b7;
		text-transform: uppercase;
		margin: 10px 0 15px 0;
	}
	&-text {
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		color: #343434;
		display: block;
		margin: 10px 0;
	}
}

.half {
	background-color: #fff;
	&_yellow {
		background-color: #f9c21b;
	}
	&_red {
		background-color: #e6543e;
	}
	&_blue {
		background-color: $bg-blue;
	}
	&_white {
		.half-text {
			padding-right: 0;
			&-small {
				color: #4b4b4b;
			}
			&-title {
				color: #000;
			}
			&-text {
				color: #9d9d9d;
				padding-right: 75px;
			}
			&-link {
				color: #e6533d;
			}
		}
	}
	&-img {
		text-align: right;
		position: relative;
		&_small {
			top: -30px;
		}
		&_large {
			top: -50px;
		}
		&_center {
			height: 100%;
			@include flexCenter;
		}
		img {
			max-width: 100%;
			height: auto;
		}
	}
	&-text {
		padding: 100px 80px 75px 100px;
		color: #fff;
		&-small {
			font-size: 10px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 2px;
			display: block;
		}
		&-title {
			font-size: 30px;
			font-family: $ff-Raleway;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 6px;
			margin: 30px 0 50px 0;
		}
		&-text {
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
			display: block;
			margin: 20px 0;
		}
		&-link {
			font-size: 14px;
			font-weight: 700;
			line-height: 24px;
			text-transform: uppercase;
			letter-spacing: 0.7px;
			display: block;
			color: #fff;
			@include transition3;
			margin-top: 60px;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.between-style-info {
		padding-right: 15px;
		&-title {
			font-size: 24px;
		}
		&-text {
			font-size: 13px;
			line-height: 20px
		}
	}
	.between-style {
		min-height: 130px;
		&-wrap {
			.btn {
				font-size: 15px;
				height: 50px;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.between-style {
		&-wrap {
			flex-direction: column;
			padding-top: 30px;
			padding-bottom: 30px;
			.btn {
				font-size: 14px;
				height: 40px;
			}
		}
	}
	.between-style-info {
		text-align: center;
		padding-bottom: 20px;
		padding-right: 0;
	}
}