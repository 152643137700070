//@import 'utils/mixins';

.review {
	padding-bottom: 70px;
	&-margin {
		margin-top: -35px;
	}
	&-item {
		background-color: #fff;
		padding: 28px 67px 15px 36px;
		border-radius: 5px;
		margin-bottom: 20px;
		&-text {
			color: #343434;
			font-size: 14px;
			line-height: 20px;
			display: block;
			margin-bottom: 15px;
			font-style: italic;
		}
		&-name {
			color: #b7b7b7;
			font-size: 10px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 2px;
		}
		&-img {
			width: 42px;
			height: 42px;
			overflow: hidden;
			border-radius: 50%;
			margin-right: 13px;
			img {
				@include responImg;
			}
		}
		&-wrap {
			display: flex;
			align-items: center;
		}
	}
}