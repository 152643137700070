//@import 'utils/mixins';
/*
	Typography
*/

.h1-title {
	font-size: 38px;
	font-weight: 700;
	text-transform: uppercase;
	color: black;
	letter-spacing: 7.6px;
	@include media-breakpoint-down(lg) {
		font-size: 28px;
	}
}

.h2-title {
	font-family: $ff-Raleway;
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 1rem;
}

.h3-title {
	font-family: $ff-Raleway;
	font-size: 17px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1.7px;
}

.h5-title {
	font-size: 14px;
	font-weight: 700;
}

.text-small {
	font-size: 11px;
	font-weight: 400;
}