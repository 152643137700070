@import 'utils/mixins';
/*
	Footer
*/
footer {
	padding-top: 85px;
	.dropdown {
		.btn-link {
			color: #383838;
			padding-left: 0;
			&:hover,
			&:focus {
				text-decoration: none;
				color: inherit;
			}
		}
		.dropdown-toggle {
			&:after {
				margin-left: 0;
				vertical-align: 0.15em;
			}
		}
	}
}
.bg-black {
	background-color: $bg-black;
	color: #b7b7b7;
}
.bg-transparent {
	color: white;
}

.footer-top-row,
.footer-middel-row {
	font-family: $ff-Raleway;
}

.footer-top-row,
.footer-middel-row,
.footer-bottom-row {
	display: flex;
	align-items: center;
	ul.nav {
		li.nav-item {
			&:first-child a.nav-link {
				padding-left: 0;
			}
			&:last-child a.nav-link {
				padding-right: 0;
			}
		}
		&.link-grey {
			&.link-social {
				a.nav-link {
					color: #686868;
				}
			}
			a.nav-link {
				color: #979797;
			}
		}
		&.link-black {
			a.nav-link {
				color: #111111;
			}
		}
	}
}

.footer-top-row {
	font-size: 16px;
	ul.nav {
		font-size: 16px;
		a.nav-link {
			font-weight: 500;
			&.active {
				font-weight: 700;
			}
		}
		&.link-social {
			font-size: 40px;
		}
	}

}

.footer-middel-row {
	border-top: 1px solid #dcdcdc;
	//color: #979797;
	min-height: 100px;
	font-size: 13px;
	font-weight: 500;
	img {
		max-height: 38px;
	}
	ul.nav {
		padding-bottom: 20px;		
	}
	.mr-auto {
		display: flex;
	}
	.nav-item {
		display: flex;
		align-items: flex-end;
	}
}

.footer-bottom-row  {
	height: 44px;
	font-size: 10px;
	.mr-auto {
		text-transform: uppercase;
		//font-weight: bold;
		letter-spacing: 2px;
		font-weight: 400;
	}
}

.footer-col {
	font-family: $ff-Raleway;
	color: #bebebe;
	.title-ul {		
		font-size: 20px;
		font-weight: 300;
		padding-bottom: 45px;
	}
	.list-unstyled {
		margin-bottom: 12px;
		a {
			color: #6e6e6e;
			padding-bottom: 17px;
			display: block;
		}
		font-size: 13px;
		font-weight: 500;
	}
	.link-social {
		padding-top: 55px;
		font-size: 40px;
		justify-content: flex-end;
		.nav-item {
			display: flex;
			align-items: flex-end;
		}
		.nav-link {
			&:last-child {
				padding-right: 0;
				color: #b7b7b7;	
			}
		}
	}
	.footer-middel-row {
		min-height: 85px;
	}
}

@include media-breakpoint-down(md) {
	footer {
		padding-top: 30px;
	}
	.footer-top-row {
		font-size: 14px;
		ul.nav {
			font-size: 14px;
			a.nav-link {				
				padding-left: 10px;
				padding-right: 10px;
			}
			&.link-social {
				//font-size: 30px;
			}
		}
	}
	.footer-middel-row {
		flex-direction: column;
		padding-top: 10px;
		.nav-link {
			padding-right: 10px;
			padding-top: .25rem;
			padding-bottom: 0.25rem;
		}
		ul.nav {
			padding-bottom: 5px;		
		}
		img {
			min-height: 30px;
		}
		.mr-auto {
			//margin-right: 0 !important;
			margin-left: 0;
		}
		.ml-auto {
			margin-left: 0 !important;
			margin-right: auto;
		}
	}
}

@include media-breakpoint-down(sm) {
	.footer-top-row {
		flex-direction: column; 
		ul.nav {
			&:not(.link-social) {
				flex-direction: column;
				a.nav-link {
					padding-left: 0;
					padding-right: 0;
				}
			}
			&.link-social {
				justify-content: center;
			}
		}
		.mr-auto,
		.ml-auto {
			width: 100%;
			text-align: center;
		}
	}
	.footer-middel-row {
		padding-bottom: 10px;
		ul.nav {
			flex-direction: column;
		}
		.mr-auto,
		.ml-auto,
		ul.nav {
			width: 100%;
			text-align: center;
		}
		ul.nav a.nav-link {
			width: 100%;
			text-align: center;
			padding-left: 0;
			padding-right: 0;
		}
		.ml-auto {
			.nav {
				.nav-item {
					&:not(:last-child){
						padding-bottom: 5px;
					}
				}
			}
		}
	}
	.footer-bottom-row {
		flex-direction: column;
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
		.mr-auto,
		.ml-auto {
			width: 100%;
			text-align: center;
		}
	}
}