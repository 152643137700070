.btn {
	border-radius: 5px;
}
.btn-round-28 {
	border-radius: 28px!important;
}
.btn-round-20 {
	border-radius: 20px;
}
//$color, $background, and $border
.btn-accent {
    @include button-variant($bg-blue, $bg-blue, $bg-blue);
    color: white;
    //border-radius: 16px;
    &:hover,
    &:focus {
    	color: white;
    }
    &:hover,
    &.hover {
    	background-color: $btn-hover;
    	border-color: $btn-hover;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:focus,
    &.active {
    	background-color: $btn-pressed;
    	border-color: $btn-pressed;
    }
}

.btn-facebook {
	@include button-variant(#49639e, #49639e, #49639e);
    color: white;
    //border-radius: 16px;
    &:hover,
    &:focus {
    	color: white;
    }
    &:hover,
    &.hover {
    	background-color: darken(#49639e, 10%);
    	border-color: darken(#49639e, 10%);
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:focus,
    &.active {
    	background-color: darken(#49639e, 15%);
    	border-color: darken(#49639e, 15%);
    }
}

.btn-google {
	@include button-variant(#d95333, #d95333, #d95333);
    color: white;
    //border-radius: 16px;
    &:hover,
    &:focus {
    	color: white;
    }
    &:hover,
    &.hover {
    	background-color: darken(#d95333, 10%);
    	border-color: darken(#d95333, 10%);
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:focus,
    &.active {
    	background-color: darken(#d95333, 15%);
    	border-color: darken(#d95333, 15%);
    }
}

.btn-secondary {
	@include button-variant(#c7c7c7, #c7c7c7, #c7c7c7);
    color: white;
    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:focus,
    &.active {
    	color: white;
    }
}

.btn-white {
	@include button-variant(white, white, white);
    color: #3f6c75;
    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:focus,
    &.active {
    	background-color: darken(white, 15%);
    	border-color: darken(white, 15%);
    }
}

.btn-blue {
    @include button-variant(#7dd1e2, #7dd1e2, #7dd1e2);
    color: white;
    //border-radius: 16px;
    &:hover,
    &:focus {
    	color: white;
    }
    &:hover,
    &.hover {
    	background-color: darken(#7dd1e2, 10%);
    	border-color: darken(#7dd1e2, 10%);
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:focus,
    &.active {
    	background-color: darken(#7dd1e2, 15%);
        border-color: darken(#7dd1e2, 15%);
        color: white;
    }
}
