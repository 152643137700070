@import 'utils/mixins';
/*
	Header
*/
/*
   Top Menu
*/
.header-box {
	display: flex;
	align-items: center;
	min-height: 119px;
	border-bottom: 1px solid $border-color;
}
.header-slider + .header-box {
	border-bottom-color: transparent;
}
.top-menu-box {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	ul {
		@include list-no;
		display: flex;
		flex-wrap: no-wrape;
		justify-content: space-between;
		flex: 0 0 40%;
    	max-width: 40%;
    	margin-bottom: 0;
    	align-items: center;
		//padding-top: 7px;
		a {				
			&:not(.btn-accent) {
				color: #4c4c4c;
				padding-left: .5rem;
				padding-right: .5rem;
				&:hover,
				&:focus {
					color: inherit;
					text-decoration: none;
				}
			}
			&.btn {
				padding-left: 20px;
				padding-right: 20px;
				line-height: 39px;
				padding-top: 0;
				padding-bottom: 0;
				border: none;
				@include media-breakpoint-down(lg) {
					line-height: 35px;
				}
			}
			font-family: $ff-Raleway;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 13px;
			@include media-breakpoint-down(lg) {
				font-size: 11px;
			}
		}
		&.top-menu-box-left {
			max-width: 39%;
			a {
				&:first-child {
					padding-left: 0;
				}
			}
		}
		&.top-menu-box-right {
			max-width: 34%;
			// li {
			// 	&:last-child {
			// 		padding-top: 4px;
			// 	}
			// }
		}
	}

	.logo-box {
		margin-left: auto;
		margin-right: auto;
		padding-right: 48px;
		padding-top: 2px;
		img {
			max-width: 104px;
		}
	}
}

.navbar-toggler {
	display: none;
}

@include media-breakpoint-down(md) {
	.navbar-toggler {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		color: rgba(0,0,0,.5);
		border-color: #eaeaea;
		padding: .25rem .75rem;
		font-size: 1.25rem;
		line-height: 1;
		background-color: transparent;
		//border: 1px solid transparent;
		border-radius: .25rem;
		.navbar-toggler-icon {
			background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#4a4a4a' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
		}
		&:focus {
			outline: 0;
		}
	}	
	.header-box {
		min-height: auto;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.top-menu-box {
		position: relative;
		flex-direction: column;
		align-items: flex-start;
		// padding-top: 15px;
		// padding-bottom: 15px;
		overflow: hidden;
		height: 41px;
		transition: height 0.3s ease-in-out;
		&.show {
			height: auto;
		}
		.logo-box {
			margin-left: 0;
			order: -1;
		}
		ul {
			flex-direction: column;
			align-items: flex-start;
			li {
				width: 100%;
			}
			&.top-menu-box-left {
				width: 100%;
				max-width: 100%;
				padding-top: 20px;
				// border-top: 1px solid #eaeaea;
				// margin-top: 13px;
				& li {
					border-top: 1px solid #eaeaea;
				}
			}
			&.top-menu-box-right {
				width: 100%;
				max-width: 100%;
				padding-top: 0;
				li:not(:last-child) {
					border-top: 1px solid #eaeaea;
					a {
						padding-left: 0;
					}
				}
			}
			a {
				font-size: 12px;
				&:not(.btn-accent) {
					display: block;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}
		}
	}
}