/*
	Variabels
*/

$img-path: '../img';

$ff-Open-Sans: 'Open Sans', sans-serif;
$ff-Roboto: 'Roboto', sans-serif;
$ff-Raleway: 'Raleway', sans-serif;
$ff-Lora: 'Lora', sans-serif;
$ff-FontAwesome: FontAwesome;
$bg-body: #f7f7f7;
$accent-color: #2dd8fb;

$bg-white: white;
$bg-blue: $accent-color;
$bg-black: black;
$bg-yellow: #f9c21b;


$border-color: #f1f1f1;

$content-title: 38px;

$text-blue: $accent-color;
$text-default-color: #4a4a4a;
$text-color-dark: black;

$text-red: #e6543e;

$btn-active: #2dd8fb;
$btn-hover: #7dd0e2;
$btn-pressed: #008ba8;

$xs-420: 'max-width: 420px';